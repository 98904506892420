import {Button as CButton} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {pushToDataLayer} from '@/utils/analytics'
import {spacing} from '../../flexible-web-toolkit/styles'

type MikmakProductIdContentItem = IContentItem<{
	dataEan: Elements.TextElement
}>

export type MikmakButtonContentItem = IContentItem<{
	text: Elements.TextElement
	variant: Elements.MultipleChoiceElement
	productIds: Elements.LinkedItemsElement<MikmakProductIdContentItem>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const MikMakButtonBlock: Block<MikmakButtonContentItem> = ({block}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice

	return (
		<div className={clsx(`text-center`, componentSpacing)}>
			<CButton
				className="swn-tag-wtb-btn mt-2"
				data-eans={block.elements.productIds
					.map((id) => id.elements.dataEan.trim())
					.join(',')}
				onClick={() => {
					pushToDataLayer({
						event: 'cta_click',
						cta_name: block.elements.text,
						cta_category: 'CTA_Button',
					})
				}}
				variant={block.elements.variant[0]?.codename ?? 'primary'}
			>
				{block.elements.text}
			</CButton>
		</div>
	)
}
