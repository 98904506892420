import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {spacing} from '../../flexible-web-toolkit/styles'

type MikmakProductIdContentItem = IContentItem<{
	dataEan: Elements.TextElement
}>

export type MikmakInPageContentItem = IContentItem<{
	widgetId: Elements.TextElement
	productIds: Elements.LinkedItemsElement<MikmakProductIdContentItem>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const MikMakInPageBlock: Block<MikmakInPageContentItem> = ({block}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice

	return (
		<div
			className={`${componentSpacing} max-w-[600px]`}
			data-mm-ids={block.elements.productIds
				.map((id) => id.elements.dataEan.trim())
				.join(',')}
			data-mm-wtbid={block.elements.widgetId}
		/>
	)
}
