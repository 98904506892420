import {useEffect, useRef, useState} from 'react'
import {
	Accordion,
	AccordionItem,
	Card,
	CardImage,
	CardFooter,
	LinkList,
	ButtonIcons,
	EllipseButton,
} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import {useRouter} from 'next/router'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'
import {fetchLocalisedDate} from '@/fetchers/fetchLocalisedDate'
import {env} from '@/utils/env/client.mjs'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {FooterCountryFlag} from '@/_new-code/products/flexible-web-toolkit/components/footer/footer-country-flag'
import {EmailSubscriptionForm} from '@/_new-code/products/flexible-web-toolkit/components/footer/email-subscription-form'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {ReusableCTAButtonComponent} from '../../blocks/cta-button'
import {LinkBlock} from '../../blocks/link'
import {SocialFooter} from './social-media-footer'
import {LegalFooter} from './legal-footer'

const formatDate = (date: string, locale: string): string => {
	const dateObj = new Date(date)
	const formattedLocale = locale.replace('_', '-')
	const formattedDate = dateObj.toLocaleDateString(formattedLocale, {
		year: 'numeric',
		month: 'long',
	})
	return formattedDate
}

export const FooterBlock: Block = ({page, globalConfig, ...context}) => {
	const globalFooter = globalConfig.elements.footerConfig[0]
	const pageFooter = page.elements.footerConfig[0]
	const footerConfig = pageFooter ?? globalFooter

	const promomatsDate = page.elements.snippetPageSeoDate

	const {locale = ''} = useRouter()

	const [isClient, setIsClient] = useState<boolean>(false)

	const size = useWindowSize()

	// TODO: remove this API call
	const {data: localisedDate, refetch} = useQuery({
		queryKey: ['localisedDate'],
		queryFn: async () => {
			if (!promomatsDate) return null

			const result = await fetchLocalisedDate(promomatsDate, locale)
			return result
		},
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	useEffect(() => {
		if (!promomatsDate) return
		void refetch()
	}, [promomatsDate, refetch])

	const elementRef = useRef<HTMLDivElement>(null)
	const triggerDataLayerEvents = (event: MouseEvent): void => {
		const targetElement = event.target

		if (targetElement instanceof HTMLAnchorElement) {
			pushToDataLayer({
				event: 'footer_nav_click',
				element_text: targetElement.innerText,
				element_id: targetElement.getAttribute('data-kontent-item-id'),
			})
		}
	}
	useEffect(() => {
		setIsClient(true)
		const divElement = elementRef.current?.querySelectorAll('a')

		divElement?.forEach((element) => {
			element.addEventListener('click', triggerDataLayerEvents)
		})

		return () => {
			divElement?.forEach((element) => {
				element.removeEventListener('click', triggerDataLayerEvents)
			})
		}
	}, [])

	if (!footerConfig) {
		// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: remove when React update their types
		return <></>
	}

	// Add a space for the cookie banner to sit
	const qualityLinks = [
		...footerConfig.elements.qualityLinks,
		{
			elements: {
				authenticationGateComponentUserRoles: [],
				icon: [],
				logoutUser: [],
				openByDefault: [],
				openInNewTab: [],
				openSubnavOnLinkClick: [],
				sublinks: [],
				text: '',
				url: '',
			},
			system: {
				codename: 'cookie_banner',
				id: 'cookie_banner',
				type: 'cookie_banner',
				name: 'Cookie Banner',
			},
		},
	]

	const connectWithText = footerConfig.elements.connectWithText

	const getBooleanValue = (value: string | undefined): boolean => {
		return value === 'yes'
	}

	const connectWithCompanyText = (() => {
		if (!connectWithText) return 'Connect with Elanco'

		const connectWithTextHasElanco =
			footerConfig.elements.connectWithTextHasElanco[0]?.codename !== 'no'

		// if the user does not select an option, or they select 'yes', append Elanco
		if (connectWithTextHasElanco) return `${connectWithText} Elanco`

		// else, don't
		return connectWithText
	})()

	const renderSocialMediaIcons = (): JSX.Element => (
		<div data-kontent-element-codename="show_social_icons">
			<SocialFooter
				brand={footerConfig.elements.brand}
				className="flex justify-center md:block"
				connectWithCompanyText={connectWithCompanyText}
				connectWithText={connectWithText}
				emailSubscriptionForm={
					footerConfig.elements.showSignup[0]?.codename === 'yes' ? (
						<EmailSubscriptionForm
							buttonText={footerConfig.elements.signupButtonText}
							placeholder={
								footerConfig.elements.signupPlaceholder
							}
							title={footerConfig.elements.signupTitle}
						/>
					) : null
				}
				facebookElancoUrl={footerConfig.elements.elancoFacebook}
				facebookToggle={getBooleanValue(
					footerConfig.elements.elancoFacebookToggle[0]?.codename
				)}
				hasBorderTop
				instagramElancoUrl={footerConfig.elements.elancoInstagram}
				instagramToggle={getBooleanValue(
					footerConfig.elements.elancoInstagramToggle[0]?.codename
				)}
				linkedInElancoUrl={footerConfig.elements.elancoLinkedin}
				linkedInToggle={getBooleanValue(
					footerConfig.elements.elancoLinkedinToggle[0]?.codename
				)}
				twitterElancoUrl={footerConfig.elements.elancoTwitter}
				twitterToggle={getBooleanValue(
					footerConfig.elements.elancoTwitterToggle[0]?.codename
				)}
				youtubeElancoUrl={footerConfig.elements.elancoYoutube}
				youtubeToggle={getBooleanValue(
					footerConfig.elements.elancoYoutubeToggle[0]?.codename
				)}
			>
				{footerConfig.elements.brand ? (
					<>
						{footerConfig.elements.brandFacebook ? (
							<div data-kontent-element-codename="brand_facebook">
								<EllipseButton
									className="mr-4 bg-blue-900 hover:bg-blue-800"
									href={footerConfig.elements.brandFacebook}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									icon={ButtonIcons.Facebook}
									iconSize="h-8 w-8"
									target="_blank"
								>
									{`${footerConfig.elements.brandFacebook}'s Facebook`}
								</EllipseButton>
							</div>
						) : null}
						{footerConfig.elements.brandTwitter ? (
							<div data-kontent-element-codename="brand_twitter">
								<EllipseButton
									className="mr-4 bg-blue-900 hover:bg-blue-800"
									href={footerConfig.elements.brandTwitter}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									icon={ButtonIcons.Twitter}
									iconSize="h-8 w-8"
									target="_blank"
								>
									{`${footerConfig.elements.brand}'s Twitter`}
								</EllipseButton>
							</div>
						) : null}
						{footerConfig.elements.brandInstagram ? (
							<div data-kontent-element-codename="brand_instagram">
								<EllipseButton
									className="mr-4 bg-blue-900 hover:bg-blue-800"
									href={footerConfig.elements.brandInstagram}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									icon={ButtonIcons.Instagram}
									iconSize="h-8 w-8"
									target="_blank"
								>
									{`${footerConfig.elements.brand}'s Instagram`}
								</EllipseButton>
							</div>
						) : null}
						{footerConfig.elements.brandYoutube ? (
							<div data-kontent-element-codename="brand_youtube">
								<EllipseButton
									className="mr-4 bg-blue-900 hover:bg-blue-800"
									href={footerConfig.elements.brandYoutube}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									icon={ButtonIcons.YouTube}
									iconSize="h-8 w-8"
									target="_blank"
								>
									{`${footerConfig.elements.brand}'s YouTube`}
								</EllipseButton>
							</div>
						) : null}
					</>
				) : (
					false
				)}
			</SocialFooter>
		</div>
	)

	const renderMainFooter = (): JSX.Element => (
		<div
			className={clsx(
				'hidden w-full md:block',
				footerConfig.elements.footerDivider[0]?.codename === 'yes'
					? 'border-t'
					: null
			)}
		>
			<LegalFooter
				className="mx-auto md:pl-24 md:pr-16"
				hasLogoOnLegalFooter={false}
				isDesktopView
			>
				<div className="flex w-full">
					<div
						className={clsx(
							'w-full',
							footerConfig.elements.footerCard[0]
								? 'md:w-3/5 lg:w-1/2'
								: null,
							'flex'
						)}
					>
						{footerConfig.elements.linksGroup.map((group) => (
							<div
								className={clsx(
									`w-1/3`,
									footerConfig.elements.footerCard[0]
										? 'lg:w-1/4'
										: 'md:w-1/4 lg:w-1/5'
								)}
								data-kontent-item-id={group.system.id}
								key={group.system.id}
							>
								<LinkList
									className="w-auto pr-6"
									locale={locale}
									title={
										<h2
											className="subhead h-14 pb-2"
											data-kontent-element-codename="title"
										>
											{group.elements.title}
										</h2>
									}
								>
									{group.elements.links.map((link) => (
										<LinkBlock
											{...context}
											block={link}
											className="hover:underline"
											globalConfig={globalConfig}
											key={link.system.id}
											page={page}
										/>
									))}
								</LinkList>
							</div>
						))}
					</div>
					{footerConfig.elements.footerCard[0] ? (
						<Card
							className="ml-auto w-1/3 flex-grow lg:w-full"
							imageOnLeft
							isFooterCard
							removeBorders
							title={
								<span
									data-kontent-element-codename="title"
									data-kontent-item-id={
										footerConfig.elements.footerCard[0]
											.system.id
									}
								>
									{
										footerConfig.elements.footerCard[0]
											.elements.title
									}
								</span>
							}
						>
							<RichTextRenderer
								{...context}
								className="p-2 text-sm"
								data-kontent-element-codename="content"
								element={
									footerConfig.elements.footerCard[0].elements
										.content
								}
								globalConfig={globalConfig}
								page={page}
							/>
							<CardImage
								alt={
									footerConfig.elements.footerCard[0].elements
										.image[0]?.description ?? undefined
								}
								data-kontent-element-codename="image"
								isFooterCardImage
								src={
									footerConfig.elements.footerCard[0].elements
										.image[0]?.url
								}
							/>
							{footerConfig.elements.footerCard[0].elements
								.button[0] ? (
								<CardFooter
									className="w-content mx-2"
									isFooterCard
								>
									<ReusableCTAButtonComponent
										{...context}
										block={
											footerConfig.elements.footerCard[0]
												.elements.button[0]
										}
										className="mx-auto w-full py-2 text-center"
										globalConfig={globalConfig}
										page={page}
									/>
								</CardFooter>
							) : null}
						</Card>
					) : null}
				</div>
			</LegalFooter>
			{footerConfig.elements.showSocialIcons[0]?.codename === 'yes' &&
				renderSocialMediaIcons()}
			<div className="mx-auto flex w-full items-center justify-between border-t px-8 pb-4 md:pl-24 md:pr-16">
				<div>
					<LinkList
						className="mb-4 mt-8 break-words underline"
						horizontal
					>
						{qualityLinks.map((kProps) => {
							if (
								kProps.system.codename === 'cookie_banner' &&
								isClient &&
								!size.isMobile
							) {
								return (
									<div
										id="teconsent"
										key={kProps.system.id}
									/>
								)
							}

							return (
								<LinkBlock
									{...context}
									block={kProps}
									className="hover:text-primary"
									globalConfig={globalConfig}
									key={kProps.system.id}
									page={page}
								/>
							)
						})}
					</LinkList>
					{env.NEXT_PUBLIC_FEATURE_FLAG_COUNTRY_SELECTOR ===
						'true' && (
						<FooterCountryFlag
							countryFlagIcon={
								footerConfig.elements.countryFlagIcon
							}
							countryName={footerConfig.elements.countryName}
							countrySelectionPageUrl={
								footerConfig.elements.countrySelectionPageUrl
							}
							isMobile={false}
						/>
					)}
					<p
						className="mt-4"
						data-kontent-element-codename="copyright_text"
					>
						{footerConfig.elements.copyrightText ||
							`© ${new Date().getFullYear()} Elanco or its affiliates.`}
					</p>
					<div className="w-full">
						<div>
							<p>{page.elements.snippetPageSeoCode}</p>
							{promomatsDate ? (
								<p>
									{page.elements.snippetPageSeoDateLabel}{' '}
									<span className="capitalize">
										{localisedDate ||
											formatDate(promomatsDate, locale)}
									</span>
								</p>
							) : null}
						</div>
					</div>
				</div>
				<svg
					className="fill-current text-primary"
					height="61.742"
					viewBox="0 0 637.38 316.17"
					width="124.47"
				>
					<title>Elanco Logo</title>
					<g>
						<polygon points="617.07 226.46 620.37 226.46 620.37 235.29 621.86 235.29 621.86 226.46 625.15 226.46 625.15 225.11 617.07 225.11 617.07 226.46" />
						<polygon points="628.83 235.29 628.83 226.09 628.9 226.09 631.7 235.29 632.91 235.29 635.79 226.09 635.85 226.09 635.85 235.29 637.35 235.29 637.35 225.11 634.66 225.11 632.42 232.67 632.35 232.67 629.94 225.11 627.33 225.11 627.33 235.29 628.83 235.29" />
						<path d="M422.66,164.28c-11.55,0-16.36,34.64-16.36,40.42,0,5.14,1.77,7.54,6.25,7.54,11.56,0,16.37-34.81,16.37-40.42S426.84,164.28,422.66,164.28Z" />
						<path d="M0,111.88,36.6,316.17l600.78-106.3V0ZM106.51,229.72H39.46L63.2,117.91h65l-7.06,23.58H86.94l-4,18.77h27.92L106,183H78.12l-4.81,22.79h38.34Zm29.68,2.09c-23.58,0-21.5-12.18-18-28.71l12-56.47c4.16-19.25,4.49-25.34,4.33-30l29-4.81c.32,4-.64,12.51-2.4,20.69L148.07,194c-3.06,14.6-3.38,18.46,2.88,18.46a10.85,10.85,0,0,0,3-.49l.81,17.33C150.79,230.69,143.89,231.81,136.19,231.81Zm71.91,1.13c-3.37-1.77-5.77-6.74-6.25-9.79-3.7,3.85-11.88,9.15-21.5,9.15-16.85,0-23.91-10.6-23.91-28.24s7.06-35.93,17.81-46.68c9.47-9.47,19.58-12.52,34.17-12.52,11.39,0,25.51,1.93,31.6,5-2.4,5.77-4.81,15.71-6.25,22.62l-6.25,29.84c-.65,2.72-.49,8.5,3.2,12.51ZM276,229.72l11.23-52.93c1.44-6.42.64-9.31-4-9.31-4.49,0-8,2.25-11.55,7.71l-11.71,54.53H232l11.07-52.13c2.57-12.51,3.53-19.89,2.25-28.08l24.7-4.65a47.23,47.23,0,0,1,1.45,9c6.41-5.6,16-9,25.18-9,20.69,0,22.79,11.72,20.22,23.75l-13,61.11Zm68.66,2.09c-23.1,0-34.33-14.76-28.39-42.67,5.93-28.08,22.94-44.28,47.32-44.28,11.87,0,18.29,3,25,9.79l-16.37,16.84c-2.4-3.36-6.58-6.09-10.42-6.09-6.42,0-11.23,5.13-14.76,22s-3.21,23.74,4.81,23.74c6.25,0,12-3.05,16.69-7.38l8.34,16.36A49.84,49.84,0,0,1,344.62,231.81Zm63.12,0c-19.57,0-30.64-10.75-30.64-29.84,0-21.33,11.56-57.1,50.7-57.1,17.32,0,30.64,8.5,30.64,29.35C458.44,195.88,446.41,231.81,407.74,231.81Z" />
						<path d="M194,170.06c-5.93,6.89-9.46,23.09-9.46,33.36,0,5.61,1.6,8,5.45,8,3.37,0,9.15-5.77,9.79-8.49l7.7-36.26a5.68,5.68,0,0,0-3.37-1.13A11.77,11.77,0,0,0,194,170.06Z" />
					</g>
				</svg>
			</div>
		</div>
	)

	const mobileFooter = (): JSX.Element => (
		<>
			{footerConfig.elements.footerCard[0] ? (
				<div className="relative top-[70px] block h-48 md:hidden">
					<Card
						className="z-10 text-center"
						hasFooterCardOnMobile
						isFooterCard
						removeBorders
						title={
							<span data-kontent-element-codename="card_title">
								{
									footerConfig.elements.footerCard[0].elements
										.title
								}
							</span>
						}
					>
						<RichTextRenderer
							className="my-2 px-2 text-sm *:*:!mb-0"
							element={
								footerConfig.elements.footerCard[0].elements
									.content
							}
							globalConfig={globalConfig}
							page={page}
							{...context}
							data-kontent-element-codename="text"
						/>
						{footerConfig.elements.footerCard[0].elements
							.button[0] ? (
							<CardFooter className="!pt-1">
								<ReusableCTAButtonComponent
									{...context}
									block={
										footerConfig.elements.footerCard[0]
											.elements.button[0]
									}
									className="w-72 text-center"
									globalConfig={globalConfig}
									page={page}
								/>
							</CardFooter>
						) : null}
					</Card>
				</div>
			) : null}
			<div
				className={clsx(
					`block md:hidden`,
					footerConfig.elements.footerCard[0]
						? 'bg-gray-100 pt-20'
						: null
				)}
			>
				<Accordion>
					{footerConfig.elements.linksGroup.map((group) => (
						<AccordionItem
							hideTitle
							key={group.system.id}
							title={group.elements.title}
						>
							{group.elements.links.map((link) => (
								<div key={link.system.id}>
									<LinkBlock
										{...context}
										block={link}
										className="hover:underline"
										globalConfig={globalConfig}
										page={page}
									/>
								</div>
							))}
						</AccordionItem>
					))}
				</Accordion>
				{footerConfig.elements.showSocialIcons[0]?.codename === 'yes' &&
					renderSocialMediaIcons()}
				<LegalFooter className="flex-col">
					<div className="w-full py-5 md:p-5">
						<LinkList className="underline" horizontal isMobile>
							{qualityLinks.map((link) => {
								if (
									link.system.codename === 'cookie_banner' &&
									size.isMobile &&
									isClient
								) {
									return (
										<div
											id="teconsent"
											key={link.system.id}
										/>
									)
								}

								return (
									<LinkBlock
										{...context}
										block={link}
										className="hover:text-primary"
										globalConfig={globalConfig}
										key={link.system.id}
										page={page}
									/>
								)
							})}
						</LinkList>
					</div>
				</LegalFooter>
				{env.NEXT_PUBLIC_FEATURE_FLAG_COUNTRY_SELECTOR === 'true' && (
					<FooterCountryFlag
						countryFlagIcon={footerConfig.elements.countryFlagIcon}
						countryName={footerConfig.elements.countryName}
						countrySelectionPageUrl={
							footerConfig.elements.countrySelectionPageUrl
						}
						isMobile
					/>
				)}
				<div className="flex w-full flex-col justify-between bg-white px-2 pb-4 text-left text-xs">
					<div>
						<p>{page.elements.snippetPageSeoCode}</p>
						{promomatsDate ? (
							<p>
								{page.elements.snippetPageSeoDateLabel}{' '}
								<span className="capitalize">
									{localisedDate ||
										formatDate(promomatsDate, locale)}
								</span>
							</p>
						) : null}
					</div>
					<p data-kontent-element-codename="copyright_text">
						{footerConfig.elements.copyrightText ||
							`© ${new Date().getFullYear()} Elanco or its affiliates.`}
					</p>
				</div>
			</div>
		</>
	)
	return (
		<div
			className="mt-auto"
			data-kontent-item-id={footerConfig.system.id}
			ref={elementRef}
		>
			{mobileFooter()}
			{renderMainFooter()}
		</div>
	)
}
